/* global WP_DEFINE_MARKET */

export default async function getMessages() {
  let locale;

  if (typeof window === 'undefined') {
    // Check if running in Node.js environment
    if (WP_DEFINE_MARKET === 'uk') {
      // eslint-disable-next-line global-require
      locale = require('../../locale/en-GB.i18n').default;
    }
    if (WP_DEFINE_MARKET === 'ie') {
      // eslint-disable-next-line global-require
      locale = require('../../locale/en-IE.i18n').default;
    }
  } else {
    if (WP_DEFINE_MARKET === 'uk') {
      locale = import('../../locale/en-GB.i18n').then(module => module.default);
    }
    if (WP_DEFINE_MARKET === 'ie') {
      locale = import('../../locale/en-IE.i18n').then(module => module.default);
    }
  }

  if (!locale) {
    throw new ReferenceError(`Unknown locale id "${WP_DEFINE_MARKET}"`);
  }

  // If in a Node.js environment (i.e., during SSR), locale is a plain JavaScript object,
  // so wrap it in a Promise for consistency with the client-side rendering case
  return Promise.resolve(locale);
}
