import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction, prepareComponents } from '@mediamonks/react-redux-component-init';
import {
  API_STATIC_PUBLIC_HOME,
  API_STATIC_PUBLIC_HOMEPAGE_SECONDARY_SHOWCASE,
  API_STATIC_PUBLIC_ICELAND_PROMOTION,
} from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { setTrackingPageData } from 'common/src/app/actions/trackingActions';
import DataLayerKeys from 'common/src/app/data/enum/Tracking/DataLayerKeys';
import Category from 'common/src/app/data/enum/Tracking/Category';
import TypicalDayMealPlan from 'components/organisms/TypicalDayMealPlan';
import Promotion from 'components/organisms/Promotion';
import { countryCode } from 'common/src/app/data/enum/Countries';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import JanuaryCampaign2021Promotion from 'components/molecules/JanuaryCampaign2021Promotion';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import HomepageCampaignBlock from '../../organisms/HomepageCampaignBlock';

import Home from './Home';

const homepageSecondaryShowcaseContentIdentifier = `${API_STATIC_PUBLIC_HOMEPAGE_SECONDARY_SHOWCASE}-${
  marketName === countryCode.IRELAND ? 'ie' : 'uk'
}`;

const mapStateToProps = state => {
  const homepageShowcaseContent = staticPageSelector(
    state,
    homepageSecondaryShowcaseContentIdentifier,
  );
  const getContentBlock = block =>
    // eslint-disable-next-line no-underscore-dangle
    homepageShowcaseContent?.nestedContent?.find(content => content?._type === block);
  return {
    pageContent: staticPageSelector(state, API_STATIC_PUBLIC_HOME),
    homepageSecondaryShowcase: {
      richText: getContentBlock(types.RICH_TEXT_BLOCK),
      video: getContentBlock(types.VIDEO_BLOCK),
      image: getContentBlock(types.IMAGE_BLOCK)?.image,
      buttonText: getContentBlock(types.TEXT_BLOCK),
    },
    icelandBackground: staticPageSelector(state, API_STATIC_PUBLIC_ICELAND_PROMOTION),
  };
};

const mapDispatchToProps = dispatch => ({
  page: () =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_HOME, true)).then(() => {
      dispatch(
        setTrackingPageData({
          [DataLayerKeys.CATEGORY]: Category.HOMEPAGE,
          [DataLayerKeys.CONTENT_GROUP_NAME]: Category.HOMEPAGE,
        }),
      );
    }),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(getStaticContent(homepageSecondaryShowcaseContentIdentifier, true)),
      dispatch(getStaticContent(API_STATIC_PUBLIC_ICELAND_PROMOTION, true)),
      dispatch(
        prepareComponents([
          Promotion,
          TypicalDayMealPlan,
          JanuaryCampaign2021Promotion,
          HomepageCampaignBlock,
        ]),
      ),
      dispatch(getStaticContent(API_STATIC_PUBLIC_HOME, true)).then(() => {
        dispatch(
          setTrackingPageData({
            [DataLayerKeys.CATEGORY]: Category.HOMEPAGE,
            [DataLayerKeys.CONTENT_GROUP_NAME]: Category.HOMEPAGE,
          }),
        );
      }),
    ]),
});

export default compose(addInitAction, connected)(Home);
